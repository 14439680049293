<!--
this component is used in Pending Policy
-->
<template>
    <QDynamicSubmenu
        :default-title="title"
        :default-buttons="[]"
        :titlelink="[]"
        baseUrl=""
        standard-submenu
        v-on="$listeners"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QMyPendingPolicySubmenu",
    props: {},
    data() {
        return {
            title: "Pending Policy",
        }
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>
